import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/index"
Vue.prototype.$baseUrl = 'http://serve.ds980529.top'
// Vue.prototype.$baseUrl = 'http://localhost:8881'
Vue.prototype.$staticUrl = 'https://blog-197e.obs.cn-south-4.myhuaweicloud.com'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import EventsBus from './eventBus'
Vue.use(EventsBus)

import './assets/css/bootstrap.min.css';
import './assets/css/index.css';
import './assets/css/main.scss';
import './assets/css/media.scss'
// mavonEditor 富文本编辑器全局注册
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

Vue.use(mavonEditor)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

