import Vue from 'vue'
const app = new Vue({})//这样做是为了在其他js模块也能使用 事件总线
Vue.prototype.$eventBus = app
export default app;
/**
 * 事件名：replyCommentEmit(comment)
 * 用户回复某个评论触发的
 * 参数：回复内容
 */

/**
 * 事件名：showSendReplyCommendEventBus(CommentObject)
 * 处于回复状态时，用于展示评论输入框
 * 参数：当前评论对象
 */

/**
 * 事件名 childClickedShowEventBus(id)
 * 导航组件，点击导航触发的事件，因为递归层级太深，所以使用eventBus的方式触发点击事件
 * 参数，当前导航列表，点击导航的ID
 */

