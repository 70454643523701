import {parseTime} from "../../../BlogAdminView/src/utils";

const getDate = (timeString, flag = false) => {
    const date = new Date(timeString);

// 获取年份
    const year = date.getFullYear();
// 获取月份（注意月份是从 0 开始计数的）
    const month = date.getMonth() + 1;
// 获取日期
    const day = date.getDate();
// 获取小时
    const hours = date.getHours();
// 获取分钟
    const minutes = date.getMinutes();
// 获取秒数
    const seconds = date.getSeconds();
    if (flag) {
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
    return year + '-' + month + '-' + day
};
const getTime = timeString => Date.parse(timeString);
const formatTime = (time, option) => {
    // 将时间戳转换为毫秒级
    const timestamp = ('' + time).length === 10 ? parseInt(time) * 1000 : +time;

    const dateTime = new Date(timestamp); // 创建一个新的Date对象，传入时间戳

    const now = Date.now(); // 获取当前时间的时间戳

    const diff = (now - timestamp) / 1000; // 计算时间差，单位为秒

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // 少于1小时
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }

    if (option) {
        // 如果传入了option参数，则调用parseTime函数处理时间格式
        return parseTime(time, option);
    } else {
        // 没有传入option参数时，默认返回年月日时分格式的字符串
        return (
            dateTime.getMonth() +
            1 +
            '月' +
            dateTime.getDate() +
            '日' +
            dateTime.getHours() +
            '时' +
            dateTime.getMinutes() +
            '分'
        );
    }
};
export {
    getDate,
    getTime,
    formatTime
}
