import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import classTag from './ClassTag'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,classTag
    },
    getters:{
        //打开或者关闭登录窗口
        showLogin: state => state.user.showLogin,
        //用户是否登录
        isLoading:state =>Boolean(state.user.userInfo),
    }
});
export default store
