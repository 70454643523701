import {getAllClass} from "@/Api/class_tag";
//初始值
const DefaultState = () => ({
  allTag: null,
  Article: null
});

const state = DefaultState();

const mutations = {
  SET_ALL_TAG: (state, payload) => {
    state.allTag = payload;
  },
  SET_ARTICLE: (state, payload) => {
    state.Article = payload;
  }
};

const actions = {
// 获取所有标签数据并分类
  async Get_All_Class_Tag({commit}) {
    try {
      const {data} = await getAllClass();
      commit('SET_ARTICLE', data);
      let allTag = [];
      data.forEach(i=>allTag.push(...i.tags));
      commit('SET_ALL_TAG',allTag)
    } catch (error) {
      console.error("获取标签数据失败:", error);
      commit('SET_ARTICLE', null);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
