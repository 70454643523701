import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: {title: '首页'},
    },
    {
        path: '/Article',
        name: 'Article',
        component: () => import('@/views/Article/index'),
        redirect: {name:'ArticleList'},
        meta: {title: '日常记录'},
        children: [
            {
                path: 'Details/:id',
                name: 'ArticleDetails',
                component: () => import('@/views/Article/ArticleDetails'),
            },
            {
                path: 'List',
                name:'ArticleList',
                component: () => import('@/views/Article/ArticleList'),
            }
        ]
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import('@/views/My/index'),
        meta: {
            title:'个人中心',
            childDisplay:true,

        },
        redirect: {name: "Profile"},
        children: [
            {
                path: '/info/profile',
                name: "Profile",
                component: () => import('@/views/My/MyInfo'),
                meta: {
                    title: '我的信息',
                    loginShow:true
                },
            },
            {
                path: '/info/settings',
                name: "Settings",
                component: () => import('@/views/My/Settings'),
                meta: {
                    title: '账号设置',
                    loginShow:true
                },
            },
        ]
    },
    {
        path: '/blog',
        name:'Blog',
        // redirect: {name: "About"},
        component: () => import('@/views/About'),
        meta: {
            title: '关于博主',
            //exact表示是否精确匹配
            //exact: false,
        },
        children: [
            {
                path: '/blog/about',
                name: 'About',
                component: () => import('@/views/About'),
                meta: {role: true}
            }
        ]
    },
]

const createRouter = () => new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({y: 0}),
    routes
})


export function resetRouter() {
    createRouter()
}

const router = createRouter();
export default router
