<template>
  <el-dialog
             :visible.sync="$store.state.user.showLogin"
             width="25%"
             center
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             @close="closLogin"
             class="loginLoginBox">
    <el-tabs
        v-model="currentTab"
    >
      <el-tab-pane :disabled="loading" label="用户登录" name="loginForm">
        <el-form ref="loginForm" :model="loginForm" :rules="loginFormRules" class="login-form">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="电子邮箱" type=""></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                auto-complete="on"
                maxlength="16"
                name="password"
                placeholder="登录密码"
                tabindex="2"

            />
            <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-lock' : 'el-icon-unlock'"/>
        </span>
          </el-form-item>
          <el-button :loading="loading" style="width:100%;margin-bottom:30px;" type="primary"
                     @click.native.prevent="handleLogin"
          >登录
          </el-button>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :disabled="loading" label="用户注册" name="createForm">
        <el-form ref="createForm" :model="createUserInfo" :rules="createUserRules" class="create-form">
          <el-form-item prop="username">
            <el-input v-model="createUserInfo.username" placeholder="电子邮箱" type=""/>
          </el-form-item>
          <el-form-item class="code-input" prop="code">
            <el-input v-model="createUserInfo.code" placeholder="验证码" type=""></el-input>
            <el-button :disabled="!sendVerification" type="primary" @click="sendVerificationCode">
              {{ sendVerification ? "获取验证码" : `${time}秒后可再次获取` }}
            </el-button>
          </el-form-item>
          <el-form-item prop="password1">
            <el-input
                :key="passwordType"
                ref="password"
                v-model="createUserInfo.password1"
                :placeholder="`${currentTab==='createForm'?'登录密码':'新登录密码'}`"
                :type="passwordType"
                auto-complete="on"
                maxlength="16"
                name="password"
                tabindex="2"
            />
            <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-lock' : 'el-icon-unlock'"/>
        </span>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input
                :key="passwordType"
                ref="password"
                v-model="createUserInfo.password2"
                :placeholder="`${currentTab==='createForm'?'确认登录密码':'确认新登录密码'}`"
                :type="passwordType"
                auto-complete="on"
                maxlength="16"
                name="password"
                tabindex="2"
            />
            <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-lock' : 'el-icon-unlock'"/>
        </span>
          </el-form-item>
          <el-button :loading="loading" style="width:100%;margin-bottom:30px;" type="primary"
                     @click.native.prevent="createUserAccount"
          >注册
          </el-button>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :disabled="loading" label="修改密码" name="editorPwdForm">
        <el-form ref="editorPwdForm" :model="createUserInfo" :rules="createUserRules" class="create-form">
          <el-form-item prop="username">
            <el-input v-model="createUserInfo.username" placeholder="电子邮箱" type=""/>
          </el-form-item>
          <el-form-item class="code-input" prop="code">
            <el-input v-model="createUserInfo.code" placeholder="验证码" type=""></el-input>
            <el-button :disabled="!sendVerification" type="primary" @click="sendVerificationCode">
              {{ sendVerification ? "获取验证码" : `${time}秒后可再次获取` }}
            </el-button>
          </el-form-item>
          <el-form-item prop="password1">
            <el-input
                :key="passwordType"
                ref="password"
                v-model="createUserInfo.password1"
                :placeholder="`${currentTab==='createForm'?'登录密码':'新登录密码'}`"
                :type="passwordType"
                auto-complete="on"
                maxlength="16"
                name="password"
                tabindex="2"
            />
            <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-lock' : 'el-icon-unlock'"/>
        </span>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input
                :key="passwordType"
                ref="password"
                v-model="createUserInfo.password2"
                :placeholder="`${currentTab==='createForm'?'确认登录密码':'确认新登录密码'}`"
                :type="passwordType"
                auto-complete="on"
                maxlength="16"
                name="password"
                tabindex="2"
            />
            <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-lock' : 'el-icon-unlock'"/>
        </span>
          </el-form-item>
          <el-button :loading="loading" style="width:100%;margin-bottom:30px;" type="primary"
                     @click.native.prevent="createUserAccount"
          >提交修改
          </el-button>
        </el-form>
      </el-tab-pane>
    </el-tabs>

  </el-dialog>
</template>

<script>
import {mapActions,} from 'vuex'
import {createUser, getVerificationCode, uploadUserPwd} from "@/Api/user";


export default {
  name: "login",
  data() {
    return {
      loginForm: {
        username: "",
        // username: "1434765231@qq.com",
        // password: "ds980529",
        password: "",
      },
      loginFormRules: {
        username: [
          {required: true, trigger: 'blur', pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, message: '请输入有效的邮箱'}
        ],
        password: [
          {required: true, trigger: 'blur', minlength: 8, maxlength: 16, message: '密码长度为8-16'}
        ]
      },
      loading: false,
      passwordType: 'password',
      createUserInfo: {
        username: "",
        password1: "",
        password2: "",
        code: ""
      },
      createUserRules: {
        username: [
          {required: true, trigger: 'blur', pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, message: '请输入有效的邮箱'}
        ],
        password1: [
          {required: true, trigger: 'blur', validator: this.validatePassword}
        ],
        password2: [
          {required: true, trigger: 'blur', validator: this.validatePassword}
        ],
        code: [
          {required: true, trigger: 'blur', message: '请填写验证码'},
          {required: true, trigger: 'blur', length: 6, message: "无效的验证码"}
        ]
      },
      sendVerification: true,
      time: 60,
      timeID: null,
      currentTab: 'loginForm',
    }
  },
  methods: {
    ...mapActions('user',['userLogin','setShowLogin','getUserLikeList','getUserInfo']),
    //显示密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    //用户登录
    async handleLogin() {
      try {
        await this.$refs.loginForm.validate();
        this.loading = true
        const {code, data} = await this.userLogin(this.loginForm);
        this.loading = false;
        if (code === 200) {
          await this.getUserInfo();
          await this.getUserLikeList()
          await this.closLogin();
        }
      }catch (e) {
        console.log(e);
      }
    },
    //创建用户与修改密码
    async createUserAccount() {
      try {
        await this.$refs[this.currentTab].validate();
        this.loading = true
        let Response;
        let info = {
          username: this.createUserInfo.username,
          password: this.createUserInfo.password1,
          code: this.createUserInfo.code,
        }
        if (this.currentTab === 'createForm') {
          Response = await createUser(info)
        } else {
          info.editor = 'userChangePwd'
          Response = await uploadUserPwd(info)
        }
        let {code, data, message} = Response;
        this.$message({
          message: message,
          type: code === 200 ? "success" : "error"
        });
        this.loading = false;
        if (code === 200) {
          this.$refs[this.currentTab].resetFields();
          this.currentTab = 'loginForm'
        }
      }catch (e) {

      }

    },
    //获取验证码
    async sendVerificationCode() {
      //this.createUserInfo.username
      let valid = ""
      await this.$refs[this.currentTab].validateField(['username'], callback => valid = callback);
      if (valid) return;
      let data = {email: this.createUserInfo.username};
      if (this.currentTab === 'editorPwdForm') data.editor = 'userChangePwd';
      const {code, message} = await getVerificationCode(data);
      this.$message({
        message: message,
        type: code === 200 ? "success" : "error"
      });
      if (code === 200) {
        this.sendVerification = false;
        this.countdownTime()
      }
    },
    //获取验证码的倒计时
    countdownTime() {
      this.timeID = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.timeID)
          this.time = 60;
          this.sendVerification = true
        }
      }, 1000)
    },
    //验证两次输入的密码
    validatePassword(rule, value, callback) {
      let val1 = this.createUserInfo.password1, val2 = this.createUserInfo.password2;
      if (val2 !== val1 || val1 === '' || val2 === '') {
        callback('两次输入的密码不一致')
      }
      callback()
    },
    //关闭登录窗口
    async closLogin(){
      await this.setShowLogin(false)
    }
  },
  destroyed() {
    clearInterval(this.timeID)
  },
  watch: {
    //切换选项清除输入框内容
    currentTab: {
      handler() {
        this.passwordType = 'password';
        this.$refs[this.currentTab].resetFields();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

::v-deep.loginLoginBox {

  .el-dialog__header,.el-dialog__body{
    background: #1e1e1e;
  }

  .el-tabs {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    .el-tabs__header {
      .el-tabs__nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-tabs__active-bar {
          display: none;
        }

        .el-tabs__item {
          font-size: 16px;
          color: $light_gray;
          transition: all .3s;

          &.is-active {
            font-weight: bolder;
            color: rgb(133, 64, 245);
          }
        }
      }
    }

    .el-form {
      .code-input {
        border: none !important;

        .el-form-item__content {
          display: flex;

          .el-input {
            border: 1px solid rgba(255, 255, 255, 0.1);
            margin-right: 24px;
          }
        }
      }

      .el-input {
        display: inline-block;
        height: 47px;

        input {
          background: transparent;
          border: 0px;
          -webkit-appearance: none;
          border-radius: 0px;
          padding: 12px 5px 12px 15px;
          color: $light_gray;
          height: 47px;
          caret-color: $cursor;

        }
      }

      .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0);
        border-radius: 5px;
        color: #454545;
      }

      .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;

        span {
          &:first-of-type {
            margin-right: 16px;
          }
        }
      }

      .title-container {
        position: relative;

        .title {
          font-size: 26px;
          color: $light_gray;
          margin: 0px auto 40px auto;
          text-align: center;
          font-weight: bold;
        }
      }

      .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  button{
    background: rgb(133, 64, 245);
    border-color: rgb(133, 64, 245);
    &.el-dialog__headerbtn{
      background: transparent;
      &:hover .el-dialog__close{
        color:rgb(133, 64, 245) ;
      }
    }
  }
}
</style>
