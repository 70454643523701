<template>
  <div id="app">
    <nav>
      <NavBar/>
    </nav>
    <main>
      <transition mode="out-in" name="fade-transform">
        <router-view/>
      </transition>
      <login/>
    </main>
  </div>
</template>
<script>

import NavBar from "@/components/NavBar/NavBar";
import login from "@/components/login";
export default {
  name: 'App',
  components: {
    NavBar,
    login
  },
}
</script>
<style lang="scss" scoped>
//#1C2327 bg
//
#app {
  display: flex;
  height: 100%;
  overflow: hidden;
  nav {
    background: #1C2327;
    flex: 0 0 199px;
    height: 100%;
  }
  main {
    flex-grow: 1;
    background-color: #121212;
  }
  ::-webkit-scrollbar{
    width:.3rem;
    height:.3rem;
    background-color:rgba(0,0,0,.02)
  }
  ::-webkit-scrollbar-track{
    border-radius:.15rem;
    box-shadow:inset .05rem .05rem .15rem rgba(0,0,0,.08)
  }
  ::-webkit-scrollbar-thumb{
    border-radius:.15rem;
    background-color:rgba(0,0,0,.1)
  }
  ::-webkit-scrollbar-thumb:hover{background-color:#3C6385}
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #app {
      display: block;
      height: 100%;
    }
  }
}
</style>
