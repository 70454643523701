/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * 传入时间错，转换为正常时间
 * @param {number} time
 * @param {Boolean} is 要不要时分秒
 */
export function getTime(time, is = true) {
  const date = new Date(time)
  const Year = date.getFullYear()
  const Month = date.getMonth() + 1
  const Dates = date.getDate()
  const Hours = date.getHours().toString().padStart(2, '0')
  const Minutes = date.getMinutes().toString().padStart(2, '0')
  const Seconds = date.getSeconds().toString().padStart(2, '0')
  if (is) {
    return `${Year}/${Month}/${Dates} ${Hours}:${Minutes}:${Seconds}`
  }
  return `${Year}/${Month}/${Dates}`
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

//根据ID跳转页面
export function scrollChange(dom) {
  document.getElementById(dom).scrollIntoView()
}

/**
 * 判断对象那些值被修改过
 * @param originData 原始值
 * @param newData 新值
 * @returns {{}} 返回被修改过的对象
 */
export function myDiff(originData, newData) {
  const dataToUpdate = {};

  for (const key in newData) {
    if (newData.hasOwnProperty(key)) {
      const newValue = newData[key];
      const oldValue = originData[key];
      // console.log(newValue)
      // console.log(oldValue)
      if (newValue !== oldValue) {
        dataToUpdate[key] = newValue;
      }
    }
  }

  return dataToUpdate;
};

/**
 * 深克隆一个对象或数组
 * @param {Object|Array} obj - 要克隆的对象或数组
 * @returns {Object|Array} - 克隆后的对象或数组
 */
export function deepClone(obj) {
  // 如果是基本类型或 null，则直接返回
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // 根据 obj 的类型创建一个空的目标对象或数组
  const clone = Array.isArray(obj) ? [] : {};

  // 遍历 obj 的所有属性或元素
  for (const key in obj) {
    // 如果 obj 本身有这个属性或元素
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // 递归克隆这个属性或元素，并将克隆后的结果赋值给目标对象或数组
      clone[key] = deepClone(obj[key]);
    }
  }

  // 返回克隆后的目标对象或数组
  return clone;
}

/**
 * 返回一个指定范围的整数
 * @returns {*}
 */
export function getRandomInt(max, min) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * 函数节流
 * @param func
 * @param delay
 * @returns {(function(...[*]=): void)|*}
 */
export function throttle(func, delay) {
  let timerId;
  return function (...args) {
    if (!timerId) {
      timerId = setTimeout(() => {
        func.apply(this, args);
        timerId = null;
      }, delay);
    }
  };
}
