import request from './request';
//登录
const userLogin = data => request({
    url: '/api/user/login',
    method: 'post',
    data,
});
//获取用户信息
const getUserInfo = token => request({
    url: '/api/user/info',
    method: 'post',
    data: {token: token}
});
//注册
const createUser = data => request({
    url: '/api/user/create',
    method: 'post',
    data,
});
//修改基本信息
const uploadUserInfo = data => request({
    url: '/api/user/upload',
    method: 'post',
    data,
});
//修改密码
const uploadUserPwd = data => request({
    url: '/api/user/upload/pwd',
    method: 'post',
    data,
});
//根据邮箱获取一个验证码
const getVerificationCode = data => request({
    url: '/api/mailer/get',
    method: 'post',
    data,
});
//用户点赞评论
const userLikeComment = data=>request({
    url: '/api/user/like',
    method: 'post',
    data,
});
//获取用户点赞列表
const userLikeList = ()=>request({
    url: '/api/user/like/list',
    method: 'post',
})
export {
    userLogin,
    getUserInfo,
    createUser,
    getVerificationCode,
    uploadUserInfo,
    uploadUserPwd,
    userLikeComment,
    userLikeList
}

