import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from "@/tools/user";
import WebSocketService from "@/websocket";

router.beforeEach(async (to, form, next) => {
    //获取所有标签
    await store.dispatch('classTag/Get_All_Class_Tag')
    //开启进度条
    NProgress.start();
    //token是否存在
    if (getToken()) {
        if (store.state.user.userInfo) {
            //存在,进入需要进入的路由
            next();
        } else {
            //不存在，当前存在token，那就获取用户信息
            await store.dispatch('user/getUserInfo');
            await store.dispatch('user/getUserLikeList');
            //window.$WS =  new WebSocketService(store.state.user.userInfo.id)
            next()
        }
    } else {
        console.log(to);
        if (to.meta.loginShow && !store.state.user.userInfo) {
            await store.dispatch('user/setShowLogin', true);
        }else {
            next()
        }

    }
    NProgress.done()
})
