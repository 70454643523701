import {getUserInfo, userLikeList, userLogin} from "@/Api/user";
import {resetRouter} from "@/router";
import {getToken, setToken, delToken} from '@/tools/user'
import {getDate} from "@/tools/time";

const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: null,
        likeList: null,
        showLogin:false,
    }
}
//这么写是为了登陆失效等操作初始化state
const state = getDefaultState()
export default {
    namespaced: true,
    state,
    mutations: {
        SET_TOKEN: (state, token) => state.token = token,
        SET_INFO: (state, info) => state.userInfo = info,
        RESET_STATE: (state) => Object.assign(state, getDefaultState()),
        SET_USER_LIKE_LIST: (state,payload) => state.likeList = payload,
        SET_SHOW_LOGIN:(state,payload) => state.showLogin = payload,
    },
    actions: {
        //登录
        async userLogin({commit}, userInfo) {
            const {username, password} = userInfo
            let response;
            try {
                response = await userLogin({username: username.trim(), password: password});
                const {data} = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
            } catch (e) {
                return e
            }
            return response
        },
        //获取用户信息
        async getUserInfo({commit, state}) {
            const response = await getUserInfo(state.token)
            const {data} = response;
            if (!data) {
                return new Error('验证失败，请重新登陆')
            }
            resetRouter();
            data.createdAt = getDate(data.createdAt);
            data.updatedAt = getDate(data.updatedAt);
            commit('SET_INFO', data);
        },
        //退出登录
        userLogout({commit}) {
            delToken() // 删除token
            resetRouter()//重置路由
            commit('RESET_STATE')
        },
        //获取用户点赞信息
        async getUserLikeList({commit}) {
            const {data} = await userLikeList();
            commit('SET_USER_LIKE_LIST', data)
        },
        //设置登录界面
        setShowLogin({commit},payload){
            commit('SET_SHOW_LOGIN', payload);
        }
    }
}
