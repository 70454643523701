<template>

  <div class="nav-container">
    <div class="user-info">
      <div class="avatar">
        <el-avatar v-if="userInfo"
                   slot="reference"
                   :size="70"
                   :src="$staticUrl+userInfo.avatar_url"
                   class="avatar"/>
        <el-avatar slot="reference" :size="70" v-else
                   src="https://blog-197e.obs.cn-south-4.myhuaweicloud.com/upload/Article/images/1696415093230-sx5bmovkv5.gif"/>
      </div>
      <div class="login-name">
        <span v-if="userInfo">{{ userInfo.nickname }}</span>
        <span v-else>跪求上天赐我五百万</span>
      </div>
    </div>
    <!-- exact表示是否精确匹配     -->

    <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        router
        background-color="transparent"
        text-color="#fff"
        active-text-color="#8540f5"
        :default-active="activePath"
        unique-opened
    >
      <template v-for="i in router">
        <el-menu-item  :key="i.name" v-if="!i.meta.childDisplay" :index="i.path">
          <span slot="title">{{ i.meta.title }}</span>
        </el-menu-item>
        <el-submenu v-else :index="i.path">
          <template slot="title">
            <span>{{ i.meta.title }}</span>
          </template>
          <el-menu-item :key="k.name" :index="k.path" v-for="k in i.children">{{ k.meta.title }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: "NavBar",
  data() {
    return {
      activePath: '/',
    }
  },
  created() {
    // currentPath.includes('/Article/Details');
    // this.activePath = '/Article'
    this.currentPathActive()
  },
  computed: {
    ...mapState('user', ['userInfo']),
    router() {
      return this.$router.options.routes
    }
  },
  methods: {
    ...mapActions('user', ['userLogout']),
    async logOut() {
      this.$confirm('是否继续退出登录?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        showClose: false,
        roundButton: true
      }).then(async () => {
        await this.userLogout();
        await this.$router.push({name: "Home"})
      }).catch(e => {

      })
    },
    currentPathActive(){
      let currentPath = window.location.hash;
      if (currentPath.includes('/Article/')){
        this.activePath = '/Article'
      }else if (currentPath.includes('/blog/')){
        this.activePath = '/blog'
      }else {
        this.activePath =currentPath.slice(1, currentPath.length);
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/nav";
.nav-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: #1e1e1e;

  a {
    text-decoration: none;

    &.active {
      color: #8540f5 !important;
    }

    &:hover {
      color: #8540f5 !important;
    }
  }

  .user-info {
    flex: 0 0 120px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .avatar {
      width: 100%;
      text-align: center;
      transition: .3s all;

      ::v-deep .el-avatar {
        width: 70px;
        height: 70px;
        transition: .3s all;

        img {
          //width: 100%;
          flex: 0 0 70px;
          border-radius: 35px;
          cursor: pointer;
        }
      }

      &:hover {
        @keyframes rotate-animation {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        .el-avatar {
          animation: rotate-animation 3s linear infinite;
        }
      }

    }

    .login-name {
      margin-top: 14px;
      margin-bottom: 12px;
      font-size: 14px;
      flex: 0 0 16px;
      line-height: 16px;
      color: #fff;
    }
  }

  &::before {
    background: #000 linear-gradient(to left, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    content: "";
    height: 5px;
    position: absolute;
    top: -5px;
    width: 100%;
  }
}

</style>
