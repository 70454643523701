import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import {getToken} from "@/tools/user";

import store from "@/store"
import router from "@/router";


// 配置公共信息。
const service = axios.create({
    // baseURL: 'http://localhost:8881',
    baseURL: 'http://serve.ds980529.top',
    timeout: 15000
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['authorization'] = getToken()
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    async response => {
        const res = response.data
        if (res.code === 403) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            await store.dispatch('user/userLogout')
            return res
        }
        return res
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
